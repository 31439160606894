import {Rook} from "@/model/Rook";
import {Knight} from "@/model/Knight";
import {Pawn} from "@/model/Pawn";
import {Bishop} from "@/model/Bishop";
import {Queen} from "@/model/Queen";
import {King} from "@/model/King";
import {Case} from "@/model/Case";

export class Board {
    constructor() {
        this.cases = [];

        this.reset();
    }

    // on reset le plateau de jeu
    reset() {
        // on définir le plateau de jeu en théorique
        for(let x = 0; x < 8; x++) {
            this.cases[x] = []
            for(let y = 0; y < 8; y++) {

                // on définie les tours noirs && les tour blanches
                if((x === 0 && y === 0) || (x === 7 && y === 0)) {
                    this.cases[x][y] = new Case(x, y, new Rook(x, y,"black"));
                } else if((x === 0 && y === 7) || (x === 7 && y === 7)) {
                    this.cases[x][y] = new Case(x, y, new Rook(x, y, "white"));
                }

                // on définie les cavaliers noirs && les cavaliers blanches
                else if((x === 1 && y === 0) || (x === 6 && y === 0)) {
                    this.cases[x][y] = new Case(x, y, new Knight(x, y,"black"));
                } else if((x === 1 && y === 7) || (x === 6 && y === 7)) {
                    this.cases[x][y] = new Case(x, y, new Knight(x, y,"white"));
                }

                // on définie les fous noirs && les fous blanches
                else if((x === 2 && y === 0) || (x === 5 && y === 0)) {
                    this.cases[x][y] = new Case(x, y, new Bishop(x, y,"black"));
                } else if((x === 2 && y === 7) || (x === 5 && y === 7)) {
                    this.cases[x][y] = new Case(x, y, new Bishop(x, y,"white"));
                }

                // on définie les reines noirs && les reines blanches
                else if(x === 3 && y === 0) {
                    this.cases[x][y] = new Case(x, y, new Queen(x, y,"black"));
                } else if((x === 3 && y === 7)) {
                    this.cases[x][y] = new Case(x, y, new Queen(x, y,"white"));
                }

                // on définie les rois noirs && les rois blanches
                else if(x === 4 && y === 0) {
                    this.cases[x][y] = new Case(x, y, new King(x, y,"black"));
                } else if((x === 4 && y === 7)) {
                    this.cases[x][y] = new Case(x, y, new King(x, y,"white"));
                }

                // on définie les pions noirs && les pions blanches
                else if(y === 1) {
                    this.cases[x][y] = new Case(x, y, new Pawn(x, y, "black"));
                } else if(y === 6) {
                    this.cases[x][y] = new Case(x, y, new Pawn(x, y,"white"));

                    // on définie les cases vides
                } else {
                    this.cases[x][y] = new Case(x, y, null);
                }
            }
        }
    }

    // permet de reset les cases selectionnable
    resetSelectCanEatCases() {
        for(let x = 0; x < 8; x++) {
            for(let y = 0; y < 8; y++) {
                this.cases[x][y].selectedCanEatMe = false;
            }
        }
    }

    /// on update le plateau de jeu
    update() {
        // on parcours les cases
        for(let x = 0; x < 8; x++) {
            for(let y = 0; y < 8; y++) {
                // on update toutes les cases
                this.cases[x][y].update(this.cases);
            }
        }
    }

    // deplace une piece
    movePiece(selectedPiece, eatX, eatY, soundOnOrOff, pieceReceivedPromotion) {
        // on sauvegarde l'etat de la case qui va etre manger
        const eatCase = {...this.cases[eatX][eatY]};

        // on déplace la piece
        selectedPiece.piece.x = eatX;
        selectedPiece.piece.y = eatY;
        this.cases[eatX][eatY].piece = selectedPiece.piece;
        this.cases[selectedPiece.x][selectedPiece.y].piece = null;

        // on vas update toutes les case pour savoir qui peut manger qui
        this.update();

        // on recupere toutes les cases en liste 1D
        const cases1D = this.cases.flatMap((sousListe) => sousListe);

        // on verifier si un roi est en echec en utilisant filter
        let oneIsCheck = cases1D.filter((aCases) => aCases.isCheck()).length > 0;

        // si le son est activé on joue le son lié au deplacement
        if(soundOnOrOff) {
            let audio;

            // si un roi est en echec on joue le son de l'echec
            if(oneIsCheck) {
                audio = new Audio(require('@/assets/sound/move-check.mp3'));
            }
            // si la piece a été promu on joue le son de la promotion
            else if(pieceReceivedPromotion) {
                audio = new Audio(require('@/assets/sound/promote.mp3'));
            }
            // si une piece a été mangé on joue le son de la capture
            else if(eatCase.piece != null) {
                audio = new Audio(require('@/assets/sound/capture.mp3'));
            // sinon on joue le son du deplacement
            } else {
                audio = new Audio(require('@/assets/sound/move.mp3'));
            }

            audio.play()

        }


        // on change le joueur qui doit jouer
        if(this.playerTurn === "white") {
            this.playerTurn = "black";
        } else {
            this.playerTurn = "white";
        }

        // on reset les casses selectionnable
        this.resetSelectCanEatCases()
    }
}