<template>
  <div class="relative bg-[#312e2b]">
    <div class="flex flex-col md:flex-row h-screen">
      <BoardComponent class="grow"/>
      <SettingsComponent />
    </div>
    <WinnerComponent v-show="gameStore.winner !== null" />
    <UpgradeComponent v-show="needUpgrade" />
  </div>
</template>

<script setup>

import BoardComponent from "@/components/BoardComponent";
import WinnerComponent from "@/components/WinnerComponent";
import SettingsComponent from "@/components/SettingsComponent";
import UpgradeComponent from "@/components/UpgradeComponent";

import {GameStore} from "../../stores/GameStore";
import {computed} from "vue";

const gameStore = GameStore();

const needUpgrade = computed(() => {
  return gameStore.update !== null;
})

</script>

<style scoped>
</style>