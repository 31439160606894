<template>
  <div class="w-screen h-screen bg-black flex flex-col justify-center items-center">
      <h1 class="text-white text-5xl pb-8">404 Page not found</h1>
      <img src="@/assets/404Gif.gif" alt="checkmate gif in 3d environement">
  </div>
</template>

<script>
export default {
  name: "notFoundPage"
}
</script>

<style scoped>
</style>