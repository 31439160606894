import wq from '@/assets/piece/wq.png';
import bq from '@/assets/piece/bq.png';
import {Rook} from "@/model/Rook";
import {Bishop} from "@/model/Bishop";

export class Queen {
    constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.image = color === 'white' ? wq : bq;
    }

    getPossibleMoves(cases) {
        const rookMoves = new Rook(this.x, this.y, this.color).getPossibleMoves(cases);
        const bishopMoves = new Bishop(this.x, this.y, this.color).getPossibleMoves(cases);

        return [...rookMoves, ...bishopMoves];
    }

    canEat(cases, caseToEat) {
        let possibleEat = this.getPossibleMoves(cases);

        for(let i = 0; i < possibleEat.length; i++) {
            if(possibleEat[i].x === caseToEat.x && possibleEat[i].y === caseToEat.y) {
                return true;
            }
        }

        return false;
    }
}