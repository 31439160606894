import {King} from "@/model/King";

export class Case {
    constructor(x, y, piece) {
        this.x = x;
        this.y= y;
        this.piece = piece;
        this.selectedCanEatMe = false;
        this.whoCanEatMe = [];
    }

    // met a jour les pieces qui peuvent venir manger cette "manger" ou se deplacer sur cette case
    update(cases) {
        this.whoCanEatMe = [];

        for(let x = 0; x < 8; x++) {
            for(let y = 0; y < 8; y++) {
                if(cases && cases[x][y] && cases[x][y].piece && cases[x][y].piece.canEat(cases, this)) {
                    this.whoCanEatMe.push(cases[x][y].piece);
                }
            }
        }
    }

    // retourne true si la case peut etre manger une piece de la couleur donnée
    colorCanEatMe(color) {
        // parmis les pieces qui peuvent manger cette case
        for(let pieceCanEatMe of this.whoCanEatMe) {

            // si une piece de la couleur donnée peut manger cette case alors on retourne true
            if(pieceCanEatMe.color === color) {
                return true;
            }
        }

        return false;
    }

    // retourne true si la case est en echec
    isCheck() {
        return this.piece && this.piece instanceof King && this.colorCanEatMe(this.piece.color === "white" ? "black" : "white");
    }
}