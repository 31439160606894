import {defineStore} from "pinia";

export const SettingsStore = defineStore("SettingsStore", {
    state: () => ({
        sound: localStorage.getItem("sound") === "true",
    }),
    actions: {
        switchVolume() {
            localStorage.setItem("sound", (!this.sound).toString());
            this.sound = !this.sound;
        }
    },
    getters: {}
});