import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import {router} from './routeur.js'
import './assets/index.css'

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import { library } from '@fortawesome/fontawesome-svg-core'
import {faVolumeHigh, faVolumeMute} from "@fortawesome/free-solid-svg-icons";

library.add(faVolumeHigh, faVolumeMute);


const pinia = createPinia();

createApp(App)
    .use(router)
    .use(pinia)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
