import { createRouter, createWebHistory } from 'vue-router'
import GamePage from "./pages/GamePage.vue"
import notFoundPage from "./pages/404.vue"

export const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: "gamePage", component: GamePage },
        {
            path: '/:patchMatch(.*)*',
            name: "notFound",
            component: notFoundPage
        }
    ]
})