<template>
  <div class="cell aspect-square relative" :class="color">

    <div v-show="theCase.selectedCanEatMe"
         class="w-2/5 h-2/5 bg-gray-800 opacity-40 rounded-full absolute ml-[30%] mt-[30%]"></div>
    <div v-show="kingInCheck"
         class="w-3/5 h-3/5 bg-red-800 opacity-80 rounded-full absolute ml-[20%] mt-[20%]"></div>
    <img v-if="theCase.piece != null" :src="theCase.piece.image" alt="icon of piece" draggable="true">
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import {GameStore} from "../../stores/GameStore";

const gameStore = GameStore();

const props = defineProps({
  px  : Number,
  py  : Number
});

const color = (props.px + props.py) % 2 === 0 ? 'bg-[#eeeed2]' : 'bg-[#769656]';

const theCase = computed(() => {
  return gameStore.board.cases[props.px][props.py];
});

const kingInCheck = computed(() => {
  return theCase.value && theCase.value.isCheck()
});

</script>

<style scoped>

@media (orientation: portrait) {
  .cell {
    height: 12vw;
    width: 12vw;
  }
}

@media (orientation: landscape) {
  .cell {
    height: 12vh;
    width: 12vh;
  }
}

</style>