<template>
  <div class="flex flex-col w-full justify-center items-center">
    <div class="w-4/5 aspect-square rounded-xl shadow drop-shadow-xl shadow-black" :class="classColor"></div>
    <span>{{props.color}} : {{ gameStore.getScoreByColor(props.color) }}</span>
  </div>
</template>

<script setup>

import {defineProps} from "vue";
import {GameStore} from "../../stores/GameStore";

const gameStore = GameStore();

const props = defineProps({
  isWinner : Boolean,
  color : String
});

let classColor = "bg-" + props.color;

if(props.isWinner) {
  classColor += " border-4 border-[#769656]";
}

</script>

<style scoped>

</style>