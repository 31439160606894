import { defineStore } from 'pinia'
import { Board } from '/src/model/Board.js';
import {Case} from "@/model/Case";
import {Pawn} from "@/model/Pawn";
import {King} from "@/model/King";
import {SettingsStore} from "./SettingsStore.js";

export const GameStore = defineStore("GameStore", {
    state: () => ({
        board: new Board(),
        selected: new Case(-1, -1, null),
        playerTurn: "white",
        winner: null,
        score: {white: 0, black: 0},
        update: null
    }),
    actions: {
        // evenement lors du click sur une casse par l'utilisateur
        clickOnCase(x, y) {
            if(this.update != null || this.winner != null) {
                return;
            }

            const settingsStore = SettingsStore();

            // si aucune casse n'est selectionner
            if(this.selected.piece == null) {

                // on reset les casses selectionnable
                this.board.resetSelectCanEatCases()

                // si la casse selectionner contient une piece et que c'est au tour du joueur
                if(this.board.cases[x][y].piece != null && this.board.cases[x][y].piece.color === this.playerTurn) {
                    // on défini la casse selectionner
                    this.selected = this.board.cases[x][y]

                    // on définie les casse selectionnable par apport a la casse selectionner
                    if(this.selected.piece != null) {
                        this.selected.piece.getPossibleMoves(this.board.cases).forEach(possibleMove => {
                            this.board.cases[possibleMove.x][possibleMove.y].selectedCanEatMe = true;
                        });
                    }
                }

            // si une casse est selectionner
            } else {

                // si la casse selectionner est selectionnable
                if(this.board.cases[x][y].selectedCanEatMe) {

                    // on stocke si le piece recoit une promotion
                    let isPromoted = false;

                    // si la piece est un pion
                    if(this.selected.piece instanceof Pawn) {
                        // on défini le fait qu'il a jouer
                        this.selected.piece.hasPlayed = true;

                        // on verifie si le pion a atteint la fin du plateau on déclanche la promotion
                        if(this.selected.piece.color === "white" && y === 0) {
                            this.update = new Pawn(x, y, "white");
                            isPromoted = true;
                        } else if(this.selected.piece.color === "black" && y === 7) {
                            this.update = new Pawn(x, y, "black");
                            isPromoted = true;
                        }
                    }

                    // on déplace la piece
                    this.board.movePiece(this.selected, x, y, settingsStore.sound, isPromoted);

                    // on change le joueur qui doit jouer
                    if(this.playerTurn === "white") {
                        this.playerTurn = "black";
                    } else {
                        this.playerTurn = "white";
                    }

                    // on verifie si la partie est terminer
                    this.checkWin();

                    // on reset la casse selectionner
                    this.selected = new Case(-1, -1, null);

                } else if(this.board.cases[x][y].piece != null && this.board.cases[x][y].piece.color === this.playerTurn) {
                    // si la casse selectionner contient une piece et que c'est au tour du joueur
                    // on défini la casse selectionner
                    this.selected = this.board.cases[x][y]

                    // on reset les casses selectionnable
                    this.board.resetSelectCanEatCases()

                    // on définie les casse selectionnable par apport a la casse selectionner
                    if(this.selected.piece != null) {
                        this.selected.piece.getPossibleMoves(this.board.cases).forEach(possibleMove => {
                            this.board.cases[possibleMove.x][possibleMove.y].selectedCanEatMe = true;
                        });
                    }
                } else {
                    // on reset les casses selectionnable
                    this.board.resetSelectCanEatCases()

                    // on reset la casse selectionner
                    this.selected = new Case(-1, -1, null);
                }
            }
        },

        // permet de savoir si la partie est terminer
        checkWin() {
            let hasWhiteKing = false;
            let hasBlackKing = false;

            // on parcours le plateau pour savoir si il reste un roi
            for(let x = 0; x < 8; ++x) {
                for(let y = 0; y < 8; y++) {

                    // si la case contient un roi
                    if(this.board.cases[x][y].piece instanceof King) {
                        // si le roi est blanc on definie qu'il reste un roi blanc
                        if(this.board.cases[x][y].piece.color === "white") {
                            hasWhiteKing = true;
                        // si le roi est noir on definie qu'il reste un roi noir
                        } else if(this.board.cases[x][y].piece.color === "black") {
                            hasBlackKing = true;
                        }
                    }
                }
            }

            // si il n'y a plus de roi blanc
            if(!hasWhiteKing) {
                // on definie le gagnant et on incremente son score
                this.winner = "black";
                this.score.black++;

            // si il n'y a plus de roi noir
            } else if(!hasBlackKing) {
                // on definie le gagnant et on incremente son score
                this.winner = "white";
                this.score.white++;
            }
        },

        // permet de recuperer le score d'une couleur
        getScoreByColor(color) {
            return this.score[color];
        },

        // permet de rejouer une partie (reset le plateau)
        replay() {
            this.board.reset();
            this.selected = new Case(-1, -1, null);
            this.playerTurn = "white";
            this.winner = null;
        },

        // permet d'update le pion
        updatePawn(piece) {
            this.update = null;

            this.board.cases[piece.x][piece.y].piece = piece
            this.board.update();
        }
    },
    getters : {}
});