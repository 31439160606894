

<template>
    <div class="flex flex-col max-h-[80%] absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-xl border-4 border-black ">
      <img v-on:click="gameStore.updatePawn(piece)" v-for="piece of pieces" :key="piece" :src="piece.image" alt="icon of piece" draggable="true" class="cell">
    </div>
</template>

<script setup>

import {GameStore} from "../../stores/GameStore";
import {computed} from "vue";
import {Queen} from "@/model/Queen";
import {Rook} from "@/model/Rook";
import {Bishop} from "@/model/Bishop";
import {Knight} from "@/model/Knight";

const gameStore = GameStore();

const pieces = computed(() => {
  const updatePiece = gameStore.update;

  if(updatePiece && updatePiece.color) {
    if(updatePiece.color === "white") {
      return [new Queen(updatePiece.x, updatePiece.y, "white"), new Rook(updatePiece.x, updatePiece.y, "white"),
        new Bishop(updatePiece.x, updatePiece.y, "white"), new Knight(updatePiece.x, updatePiece.y, "white")]
    } else {
      return [new Queen(updatePiece.x, updatePiece.y, "black"), new Rook(updatePiece.x, updatePiece.y, "black"),
        new Bishop(updatePiece.x, updatePiece.y, "black"), new Knight(updatePiece.x, updatePiece.y, "black")]
    }
  }
  return [];
})

</script>

<style scoped>
@media (orientation: portrait) {
  .cell {
    height: 18vw;
    width: 18vw;
  }
}

@media (orientation: landscape) {
  .cell {
    height: 18vh;
    width: 18vh;
  }
}

</style>