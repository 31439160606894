import wr from '@/assets/piece/wr.png';
import br from '@/assets/piece/br.png';

export class Rook {
    constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.image = color === 'white' ? wr : br;
    }

    getPossibleMoves(cases) {

        // calcule des déplacements possibles de la tour
        const possibleMoves = [];

        // axe horizontal : vers la droite
        let x = this.x + 1;
        while(x < 8) {
            if(cases[x][this.y].piece !== null) {
                if(cases[x][this.y].piece.color !== this.color) {
                    possibleMoves.push({x: x, y: this.y});
                }

                break;
            }

            possibleMoves.push({x: x, y: this.y});
            x += 1;
        }

        // axe horizontal : vers la gauche
        x = this.x - 1;
        while(x >= 0) {
            if(cases[x][this.y].piece !== null) {
                if(cases[x][this.y].piece.color !== this.color) {
                    possibleMoves.push({x: x, y: this.y});
                }

                break;
            }

            possibleMoves.push({x: x, y: this.y});
            x -= 1;
        }

        // axe vertical : vers le bas
        let y = this.y + 1;
        while(y < 8) {
            if(cases[this.x][y].piece !== null) {
                if(cases[this.x][y].piece.color !== this.color) {
                    possibleMoves.push({x: this.x, y: y});
                }

                break;
            }

            possibleMoves.push({x: this.x, y: y});
            y += 1;
        }

        // axe vertical : vers le haut
        y = this.y - 1;
        while(y >= 0) {
            if(cases[this.x][y].piece !== null) {
                if(cases[this.x][y].piece.color !== this.color) {
                    possibleMoves.push({x: this.x, y: y});
                }

                break;
            }

            possibleMoves.push({x: this.x, y: y});
            y -= 1;
        }

        return possibleMoves;
    }

    canEat(cases, caseToEat) {
        let possibleEat = this.getPossibleMoves(cases);

        for(let i = 0; i < possibleEat.length; i++) {
            if(possibleEat[i].x === caseToEat.x && possibleEat[i].y === caseToEat.y) {
                return true;
            }
        }

        return false;
    }
}