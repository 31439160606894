import wb from '@/assets/piece/wb.png';
import bb from '@/assets/piece/bb.png';

export class Bishop {
    constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.image = color === 'white' ? wb : bb;
    }

    getPossibleMoves(cases) {
        // calcule des déplacements possibles du fou
        const possibleMoves = [];

        // axe diagonal: haut droite
        let x = this.x + 1;
        let y = this.y - 1;

        while(y >= 0 && x < 8) {
            if(cases[x][y].piece !== null) {
                if(cases[x][y].piece.color !== this.color) {
                    possibleMoves.push({x: x, y: y});
                }

                break;
            }

            possibleMoves.push({x: x, y: y});

            x += 1;
            y -= 1;
        }

        // axe diagonal: haut gauche
        x = this.x - 1;
        y = this.y - 1;

        while(y >= 0 && x >= 0) {
            if(cases[x][y].piece !== null) {
                if(cases[x][y].piece.color !== this.color) {
                    possibleMoves.push({x: x, y: y});
                }

                break;
            }

            possibleMoves.push({x: x, y: y});

            x -= 1;
            y -= 1;
        }

        // axe diagonal: bas droite
        x = this.x + 1;
        y = this.y + 1;

        while(y < 8 && x < 8) {
            if(cases[x][y].piece !== null) {
                if(cases[x][y].piece.color !== this.color) {
                    possibleMoves.push({x: x, y: y});
                }

                break;
            }

            possibleMoves.push({x: x, y: y});

            x += 1;
            y += 1;
        }

        // axe diagonal: bas gauche
        x = this.x - 1;
        y = this.y + 1;

        while(y < 8 && x >= 0) {
            if(cases[x][y].piece !== null) {
                if(cases[x][y].piece.color !== this.color) {
                    possibleMoves.push({x: x, y: y});
                }

                break;
            }

            possibleMoves.push({x: x, y: y});

            x -= 1;
            y += 1;
        }

        return possibleMoves;
    }

    canEat(cases, caseToEat) {
        let possibleEat = this.getPossibleMoves(cases);

        for(let i = 0; i < possibleEat.length; i++) {
            if(possibleEat[i].x === caseToEat.x && possibleEat[i].y === caseToEat.y) {
                return true;
            }
        }

        return false;
    }
}