<template>
  <div class="winnerModal">
    <div>
      <div class="h-32 bg-[#769656] flex flex-col justify-center items-center rounded-xl">
        <h1 class="text-3xl font-semibold text-center text-white">Winner is {{gameStore.winner}}</h1>
      </div>
      <div class="flex flex-row w-full mt-10">
        <WinnerPictureComponent v-for="color in ['black', 'white']" :color="color" :isWinner="gameStore.winner === color" :key="color" />
      </div>
    </div>

    <button v-on:click="gameStore.replay()" class="mb-8 text-white bg-[#769656] w-4/5 h-12 ml-[10%] rounded-xl hover:bg-[#95bb4a] active:bg-[#6c8735]">Rejouer</button>
  </div>
</template>

<script setup>

import WinnerPictureComponent from "@/components/WinnerPictureComponent";
import {GameStore} from "../../stores/GameStore";

const gameStore = GameStore();

</script>

<style scoped>
.winnerModal {
  height: 80vh;
  position: absolute;
  background-color: white;
  box-shadow: 0 0 10px black;
  border-radius: 20px;
  left: 0;
  top: 0;
  margin-top: 10vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (orientation: portrait) {
  .winnerModal {
    width: 70vw;
    margin-left: 15vw;
  }
}

@media (orientation: landscape) {
  .winnerModal {
    width: 30vw;
    margin-left: 35vw;
  }
}
</style>