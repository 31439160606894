<template>
  <div>
    <font-awesome-icon v-show="settingsStore.sound" v-on:click="settingsStore.switchVolume()" icon="fa-solid fa-volume-high" class="text-white ml-4 mb-4 md:ml-0 md:mb-0 md:mr-8 md:mt-8 text-xl" />
    <font-awesome-icon v-show="!settingsStore.sound" v-on:click="settingsStore.switchVolume()" icon="fa-solid fa-volume-mute" class="text-white ml-4mb-4 md:ml-0 md:mb-0 md:mr-8 md:mt-8  text-xl" />
  </div>
</template>

<script setup>

import {SettingsStore} from "../../stores/SettingsStore";

const settingsStore = SettingsStore();

</script>

<style scoped>

</style>