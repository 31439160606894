import wk from '@/assets/piece/wk.png';
import bk from '@/assets/piece/bk.png';

export class King {
    constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.image = color === 'white' ? wk : bk;
    }

    getPossibleMoves(cases) {
        let possibleMoves = [];

        // around: 8 cases
        for(let x = this.x - 1; x <= this.x + 1; x++) {
            for(let y = this.y - 1; y <= this.y + 1; y++) {

                // si la case est dans le plateau
                if(x >= 0 && x < 8 && y >= 0 && y < 8) {

                    // si la piece est null ou si la piece est de couleur différente
                    // ET si la piece ne peut pas etre manger par une piece de couleur opposé
                    if((cases[x][y].piece === null || cases[x][y].piece.color !== this.color)
                        && !cases[x][y].colorCanEatMe(this.color === 'white' ? 'black' : 'white')) {

                        possibleMoves.push({x: x, y: y});
                    }
                }
            }
        }

        return possibleMoves;
    }

    canEat(cases, caseToEat) {
        let possibleEat = this.getPossibleMoves(cases);

        for(let i = 0; i < possibleEat.length; i++) {
            if(possibleEat[i].x === caseToEat.x && possibleEat[i].y === caseToEat.y) {
                return true;
            }
        }

        return false;
    }
}