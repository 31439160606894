<template>
  <div class="flex flex-col h-full justify-center items-center">
    <div v-for="y in 8" :key="y" class="flex flex-row">
      <div v-for="x in 8" :key="x">
        <CellComponent :px="x - 1" :py="y - 1" v-on:click="gameStore.clickOnCase(x - 1, y - 1)"/>
      </div>
    </div>
  </div>
</template>

<script setup>

import CellComponent from "./CellComponent";
import {GameStore} from "../../stores/GameStore";

const gameStore = GameStore();

</script>

<style scoped>

</style>