<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script setup>
// TODO: prise en passant
// TODO: castling
// TODO: checkmate

// on charge les paramètres enregistrés dans le local storage
if(!localStorage.getItem("sound")) {
  localStorage.setItem("sound", "true");
}
</script>

<style>

</style>
