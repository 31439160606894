import wp from '@/assets/piece/wp.png';
import bp from '@/assets/piece/bp.png';

export class Pawn {
    constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.image = color === 'white' ? wp : bp;
        this.hasPlayed = false;
    }

    getPossibleMoves(cases) {
        let possibleMoves = [];

        // 1 case devant
        let y = this.y + (this.color === 'white' ? -1 : 1);
        if(y >= 0 && y < 8 && cases[this.x][y].piece === null) {
            possibleMoves.push({x: this.x, y: y});
        }

        // 2 cases devant
        if(!this.hasPlayed) {
            y = this.y + (this.color === 'white' ? -2 : 2);
            if(y >= 0 && y < 8 && cases[this.x][y].piece === null) {
                possibleMoves.push({x: this.x, y: y});
            }
        }

        // diagonale gauche
        let x = this.x - 1;
        y = this.y + (this.color === 'white' ? -1 : 1);
        if(x >= 0 && y >= 0 && x < 8 && y < 8 && cases[x][y].piece !== null && cases[x][y].piece.color !== this.color) {
            possibleMoves.push({x: x, y: y});
        }

        // diagonale droite
        x = this.x + 1;
        y = this.y + (this.color === 'white' ? -1 : 1);
        if(x >= 0 && y >= 0 && x < 8 && y < 8 && cases[x][y].piece !== null && cases[x][y].piece.color !== this.color) {
            possibleMoves.push({x: x, y: y});
        }


        return possibleMoves;
    }

    canEat(cases, caseToEat) {
        let possibleEat = [];

        // dans le cas des pions, on ne peut pas manger en avançant on ne peut que manger en diagonale
        // diagonale gauche
        let x = this.x - 1;
        let y = this.y + (this.color === 'white' ? -1 : 1);
        if(x >= 0 && y >= 0 && x < 8 && y < 8) {
            possibleEat.push({x: x, y: y});
        }

        // diagonale droite
        x = this.x + 1;
        y = this.y + (this.color === 'white' ? -1 : 1);
        if(x >= 0 && y >= 0 && x < 8 && y < 8) {
            possibleEat.push({x: x, y: y});
        }

        for(let i = 0; i < possibleEat.length; i++) {
            if(possibleEat[i].x === caseToEat.x && possibleEat[i].y === caseToEat.y) {
                return true;
            }
        }

        return false;
    }
}