import wn from '@/assets/piece/wn.png';
import bn from '@/assets/piece/bn.png';

export class Knight {
    constructor(x, y, color) {
        this.x = x;
        this.y = y;
        this.color = color;
        this.image = color === 'white' ? wn : bn;
    }

    getPossibleMoves(cases) {
        // calcule des déplacements possibles du cavalier
        const possibleMoves = [];

        // 8 cases possibles
        const moves = [
            {x: this.x - 1, y: this.y - 2},
            {x: this.x + 1, y: this.y - 2},
            {x: this.x + 2, y: this.y - 1},
            {x: this.x + 2, y: this.y + 1},
            {x: this.x + 1, y: this.y + 2},
            {x: this.x - 1, y: this.y + 2},
            {x: this.x - 2, y: this.y + 1},
            {x: this.x - 2, y: this.y - 1}
        ];

        for(let i = 0; i < moves.length; i++) {
            const move = moves[i];

            if(move.x >= 0 && move.x < 8 && move.y >= 0 && move.y < 8) {
                if(cases[move.x][move.y].piece === null || cases[move.x][move.y].piece.color !== this.color) {
                    possibleMoves.push(move);
                }
            }
        }

        return possibleMoves;
    }

    canEat(cases, caseToEat) {
        let possibleEat = this.getPossibleMoves(cases);

        for(let i = 0; i < possibleEat.length; i++) {
            if(possibleEat[i].x === caseToEat.x && possibleEat[i].y === caseToEat.y) {
                return true;
            }
        }

        return false;
    }
}